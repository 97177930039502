import React from "react";
import Layout from "../components/layout"
import {getCurrentLocale, getTranslatedValue} from "../helpers/fields";
import {Context} from "../context/provider";
import Menu from "../components/menu";
import Helmet from "react-helmet";
import Header from "../components/header";

class Search extends React.Component {
    render() {
        const locale = getCurrentLocale(this);
        const translations = this.props.pageContext.translations;
        const recordsForSearch = this.props.pageContext.recordsForSearch;

        return (
            <Layout page={this} title={getTranslatedValue(this, translations.search)} lang={locale}
                    translations={translations}>
                <form className='search-form'>
                    <div className="tipue_search_group">
                        <input type="search" name="q" id="tipue_search_input" pattern=".{1,}"
                               title="At least 3 characters" required
                               placeholder={getTranslatedValue(this, translations.search_placeholder)}
                        />
                        <button type="submit" className="tipue_search_button">
                            <div className="tipue_search_icon">&#9906;</div>
                        </button>
                    </div>
                </form>

                <div id="tipue_search_content" className="wrapper"></div>
                <input type="hidden" id="tipue_search_data" defaultValue={JSON.stringify(recordsForSearch)}/>
                <input type="hidden" id="tipue_search_no_results" defaultValue={getTranslatedValue(this, translations.noresults)}/>
                <Context.Consumer>{context => (
                    <>
                        <input type="hidden" id="tipue_search_context_history" defaultValue={JSON.stringify(context)} />
                    </>
                )}
                </Context.Consumer>
            </Layout>
        );
    }
}

export default Search;